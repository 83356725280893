export default {
  appUrl: '__appUrl__',
  apiUrl: '__apiUrl__',
  revision: '__revision__',
  version: '__version__',
  appsignalKey: '__appsignalKey__',
  captchafoxKey: '__captchafoxKey__',
  maptilerKey: '__maptilerKey__',
  cnrMode: '__cnrMode__',
  experimentalFeatures: '__experimentalFeatures__',
};
